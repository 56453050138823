import { useContext, useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import { CartContext } from '../../contexts/cart';
import Produto from '../../components/produto/fechamento';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';

function Checkout() {

  const navigate = useNavigate();
  const { cart, subtotalCart, descontoCart, cupomCart, entregaCart, totalCart,
    idEstabelecimentoCart, idCupomCart, setCart, setIdCupomCart } = useContext(CartContext);

  const [enderecos, setEnderecos] = useState([]);
  const [endereco, setEndereco] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUF] = useState('');
  const [cep, setCEP] = useState('');
  const [codCidade, setCodCidade] = useState('');
  const [cartao_credito, setCartaoCredito] = useState(true);
  const [cartao_debito, setCartaoDebito] = useState(false);
  const [dinheiro, setDinheiro] = useState(false);
  const [valor_troco, setValorTroco] = useState(0);
  const [pix, setPix] = useState(false);

  useEffect(() => {

    if (cart.length == 0) {
      navigate('/');
      return;
    }

    api.get('/v1/usuarios/enderecos', {
      params: {
        cod_cidade: localStorage.getItem('session.CodCidade')
      }
    })
      .then(response => setEnderecos(response.data))
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {

    if (cart.length == 0) {
      navigate('/');
      return;
    }

  }, []);

  useEffect(() => {

    if (cart.length == 0) {
      navigate('/');
      return;
    }
  }, [cart]);

  function FinalizarPedido() {
    api.post('/v1/pedidos', {
      id_estabelecimento: idEstabelecimentoCart,
      id_cupom: idCupomCart ?? 0,
      vl_taxa_entrega: entregaCart,
      vl_desconto: descontoCart,
      vl_total: totalCart,
      endereco,
      complemento,
      bairro,
      cidade,
      uf,
      cep,
      cod_cidade: codCidade,
      itens: cart
    })
      .then(response => {
        if (response.data) {
          sessionStorage.removeItem('sessionCart');
          setCart([]);
          setIdCupomCart(0);
          navigate('/pedidos');
        } else {
          alert('Erro ao enviar o pedido');
        }
      })
      .catch(err => console.log(err));
  }

  function SelecionarEndereco(end) {
    setEndereco(end.endereco);
    setComplemento(end.complemento);
    setBairro(end.bairro);
    setCidade(end.cidade);
    setUF(end.uf);
    setCEP(end.cep);
    setCodCidade(end.cod_cidade);
  }

  return <div className="container-fluid mt-page">
    <Navbar />

    <div className="row col-lg-6 offset-lg-3">
      <div>
        <h2 className="mt-2">Finalizar Pedido</h2>
      </div>

      <div className="row mt-3">
        {
          cart.map(prod => {
            return <div key={prod.id_carrinho}>
              <Produto nome={prod.nome}
                valor_total={prod.vl_unit * prod.qtd}
                qtd={prod.qtd}
                valor_unit={prod.vl_unit}
                id_carrinho={prod.id_carrinho}
                url_foto={prod.url_foto}
                detalhes={prod.detalhes} />
            </div>
          })
        }
      </div>

      <div className="row align-items-end mt-5">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <span>Sub-Total</span>
          <span>{new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(subtotalCart)}</span>
        </div>

        <div className="col-12 d-flex justify-content-between align-items-center mt-2">
          <small>Desconto {descontoCart > 0 ?
            <span className="text-success">{`(cupom ${cupomCart})`}</span>
            : null}
          </small>
          <span>
            - {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(descontoCart)}
          </span>
        </div>

        <div className="col-12 d-flex justify-content-between align-items-center mt-2">
          <span>Taxa de Entrega</span>
          <span>{new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(entregaCart)}</span>
        </div>

        <div className="col-12 d-flex justify-content-between align-items-center mt-3">
          <b>Total</b>
          <h3>{new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(totalCart)}</h3>
        </div>
      </div>

      <div className="mt-5 mb-3">
        <h4>Endereço de Entrega</h4>
      </div>

      <div className="row">
        <ul>
          {
            enderecos.map(endereco => {
              return <li className="list-group-item p-3" key={(endereco.id_endereco)}>
                <input className="form-check-input" type="radio" name="flexRadioDefault"
                  id={`flexRadioDefault$(endereco.id_endereco}`}
                  onClick={(e) => SelecionarEndereco(endereco)} />
                <label className="form-check-label ms-2" htmlFor={`flexRadioDefault$(endereco.id_endereco}`}>
                  <b>{endereco.endereco} {endereco.complemento.length > 0 ? ` - ${endereco.complemento}` : null}</b>
                  <small className="d-block">{endereco.cidade} - {endereco.uf}</small>
                </label>
              </li>
            })
          }
        </ul>
      </div>

      <div className="mt-5 mb-1">
        <h4>Forma de Pagamento</h4>
      </div>

      <div className="col-12 d-flex mt-3">
        <input className="form-check-input" type="checkbox" name="flexCheckDefault" id={"flexCheckDefaultCC"} onClick={(e) => setCartaoCredito(e.target.checked)} />
        <label className="form-check-label ms-3 d-lg-inline-grid" htmlFor={"flexCheckDefaultCC"} ><b>CARTÃO DE CRÉDITO</b></label>
      </div>

      <div className="col-12 d-flex mt-2">
        <input className="form-check-input" type="checkbox" name="flexCheckDefault1" id={"flexCheckDefaultCD"} onClick={(e) => setCartaoDebito(e.target.checked)} />
        <label className="form-check-label ms-3 d-lg-inline-grid" htmlFor={"flexCheckDefaultCD"} ><b>CARTÃO DE DÉBITO</b></label>
      </div>

      <div className="col-4 d-flex mt-2">
        <input className="form-check-input" type="checkbox" name="flexCheckDefault2" id={"flexCheckDefaultDI"} onClick={(e) => setDinheiro(e.target.checked)} />
        <label className="form-check-label ms-3 d-lg-inline-grid" htmlFor={"flexCheckDefaultDI"} ><b>DINHEIRO</b></label>
      </div>

      {dinheiro ?
        <div className="col-4 d-flex">
          <span className="mt-2 me-3">Troco</span>
          <NumericFormat className="form-control" value={0}
            allowLeadingZeros
            decimalSeparator=","
            thousandSeparator='.'
            decimalScale={2}
            prefix={"R$ "}
            onValueChange={(values, sourceInfo) => { setValorTroco(values.value); }} />
        </div>
        : null}

      <div className="col-12 d-flex mt-2">
        <input className="form-check-input" type="checkbox" name="flexCheckDefault1" id={"flexCheckDefaultPX"} onClick={(e) => setCartaoDebito(e.target.checked)} />
        <label className="form-check-label ms-3 d-lg-inline-grid" htmlFor={"flexCheckDefaultPX"} ><b>PIX</b></label>
      </div>

      <div className="row mb-5">
        <button onClick={FinalizarPedido} className="btn btn-lg btn-danger mt-4" disabled={endereco.length == 0}>Finalizar Pedido</button>
      </div>

    </div>
  </div >
}


export default Checkout;