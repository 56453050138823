import './style.css';

function Rodape() {
  const ano = new Date().getFullYear();
  
  return <div className="row footer border-top mt-5">
    <div className="col-12 mt-4 ms-3">
      <p className="copyright">
        Copyright © 2012-{ano} Advance Systems - Todos os direitos reservados
      </p>
    </div>
  </div>;
}

export default Rodape;
