/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Navbar from '../../components/navbar';
import Estabelecimento from '../../components/estabelecimento';
import api from '../../services/api';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

function Busca() {
  
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [resultado, setResultado] = useState([]);
  const [mais, setMais] = useState(true);
  const [processando, setProcessando] = useState(false);
  const [pagina, setPagina] = useState(1);
  
  var id_categoria = searchParams.get('id_categoria');
  var id_banner = searchParams.get('id_banner');
  var descricao = searchParams.get('descr') ?? 'Busca';
  var busca = searchParams.get('q') ?? '';
  var pg = 0;
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function ListarEstabelecimentos(indReset) {
    
    setProcessando(true);
    
    pg = indReset ? 1 : pagina + 1;
    
    api.get('/v1/estabelecimentos', {
      params: {
        cod_cidade: localStorage.getItem('sessionCodCidade'),
        nome: busca,
        id_categoria: id_categoria,
        id_banner: id_banner,
        pagina: pg
      }
    })
      .then(response => {
        if (indReset) {
          setResultado(response.data);
          setPagina(1);
        } else {
          setResultado((oldArray) => [...oldArray, ...response.data]);
          setPagina(pagina + 1);
        }
        setMais(response.data.length >= 10);
        setProcessando(false);
      })
      .catch(err => {
        console.log(err);
        setProcessando(false);
      })
  }
  
  useEffect(() => {
    ListarEstabelecimentos(true);
  }, [location])
  
  return <div className="container-fluid mt-page">
    <Navbar />
    
    <div className="row m-2">
      <h3>{descricao}</h3>
      {busca.length > 0 ? <small className="mb-4 text-secondary">Pesquisando Por: {busca}</small> : null}
    </div>
    
    <div className="row m-2">
      {
        resultado.map(estabelecimento => {
          return <Estabelecimento
            key={estabelecimento.id_estabelecimento}
            url_imagem={estabelecimento.url_logo}
            nome={estabelecimento.nome}
            avaliacao={parseFloat(estabelecimento.avaliacao).toFixed(1)}
            categoria={estabelecimento.categoria}
            id_estabelecimento={estabelecimento.id_estabelecimento} />
                })
      }
    </div>

    { processando ?
      <div className="text-center m-5">
        <span className="spinner-grow spinner-grow-sm text-danger me-2"></span>
        <span className="text-danger">Buscando...</span>
      </div> : null
    }

    { !processando && mais ?
      <div className="row m-4">
         <button onClick={(e) => ListarEstabelecimentos(false)} className="btn btn-outline-danger">Ver mais...</button>
      </div> : null
    }
    
  </div>
}

export default Busca;