import axios from 'axios';

//var sessionToken = localStorage.getItem('sessionToken') ? localStorage.getItem('sessionToken') : null;

const api = axios.create({
  baseURL: 'http://advance.myddns.me:215',
  //headers: { Authorization: `Bearer ${sessionToken}` }
});

api.interceptors.request.use(req => {
  if (localStorage.getItem('sessionToken')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('sessionToken')}`;
  }
  
  return req;
},
  (err) => {
    console.log(err);
  }
);

api.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('sessionToken');
    document.location = '/login';
  }
  return error;
});

export default api;